import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { Button } from "rsuite";

import {
  Stack,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { TextFieldComponent } from "../../../../../components";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { merchantApi } from "../../../../../redux/api/apiConfig";
import { FEE, EXCHANGE_RATE } from "../../../../../ApiUrl";

const validationSchema = yup.object({
  base_currency: yup
    .string("Select base currency")
    .required("Base currency is required!"),

  quote_currency: yup
    .string("Enter quote currency")
    .required("Quote currency is required!"),

  rate: yup
    .number("Enter rate")
    .min(0, "Please enter rate")
    .required("rate is required!"),
});

const AddNewOfferModal = ({
  onClose,
  isEdit,
  isOpen,
  onSubmit,
  side,
  offerToEdit,
  offers,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState(null);

  const { user } = useSelector((state) => state.profileReducer) || {};
  const { coins } = useSelector((state) => state.walletsReducer) || {};
  const currency = user?.mto?.currency?.code;

  const existingOfferCoins = offers.map((offer) => offer.coin.symbol);
  console.log(existingOfferCoins);

  const filteredCoins = coins.filter(
    (coin) =>
      !existingOfferCoins.includes(coin.symbol) && coin.symbol !== currency
  );

  useEffect(() => {
    if (isEdit) {
      const coin = coins.find(
        (coin) => coin.symbol === offerToEdit.coin.symbol
      );
      setSelectedCoin(coin);
    }
  }, []);

  const addClickHandler = async (values) => {
    const { base_currency, rate } = values;
    if (!isEdit) {
      try {
        setLoading(true);
        const response = await merchantApi.post(EXCHANGE_RATE, {
          coin: base_currency,
          is_active: true,
          currency: user?.mto?.currency?.id,
          rate,
        });
        console.log(response);
        onSubmit();

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
        toast.error(err?.data?.data?.message || "Something went wrong");
      }
    } else {
      try {
        setLoading(true);
        const response = await merchantApi.patch(
          `${EXCHANGE_RATE}${offerToEdit.id}/`,
          {
            coin: base_currency,
            is_active: offerToEdit?.is_active,
            currency: user?.mto?.currency?.id,
            rate,
          }
        );
        console.log(response);
        onSubmit();

        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
        toast.error(err?.data?.data?.message || "Something went wrong");
      }
    }
  };

  console.log(offerToEdit);

  const formik = useFormik({
    initialValues: isEdit
      ? {
          quote_currency: currency,
          base_currency: (() => {
            const coin = coins.find(
              (coin) => coin.symbol === offerToEdit.coin.symbol
            );
            return coin.id;
          })(),
          rate: offerToEdit.rate,
        }
      : { quote_currency: currency },
    validateOnBlur: true,
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: addClickHandler,
  });

  const handleCloseModal = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  console.log(formik);
  return (
    <>
      <Dialog
        open={isOpen}
        fullWidth
        maxWidth={"xs"}
        onClose={handleCloseModal}
        scroll={"body"}
        BackdropProps={{ style: { backgroundColor: "rgba(11, 5, 35, 0.8)" } }}
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRadius: "8px",
            // width: '628px',
            // minHeight: '550px',
            // maxHeight: '90vh',
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          {isEdit ? "Edit Offer" : "Add New Exchange Offer"}
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent
            sx={{ paddingTop: "5px !important", paddingBottom: "0 !important" }}
          >
            <Stack
              spacing={1}
              direction={"column"}
              alignItems={"center"}
              mb={1}
            >
              <Stack spacing={0} width={"100%"}>
                <Typography>Base Currency</Typography>
                <Autocomplete
                  // disablePortal
                  id="base_currency"
                  value={selectedCoin}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(e, newValue) => {
                    formik.setFieldValue("base_currency", newValue.id);
                    console.log(newValue);
                    setSelectedCoin(newValue);
                  }}
                  options={filteredCoins}
                  getOptionLabel={(option) => option.symbol}
                  sx={{ flexGrow: 1, width: "100%" }}
                  renderInput={(params) => {
                    return <TextField placeholder="Select Coin" {...params} />;
                  }}
                />
              </Stack>
              {/* <TextFieldComponent
                formik={formik}
                name={'base_currency'}
                type={'text'}
                label={'Base Currency'}
              /> */}

              <TextFieldComponent
                formik={formik}
                name={"quote_currency"}
                type={"text"}
                label={"Quote Currency"}
                disabled={true}
              />
              <TextFieldComponent
                formik={formik}
                name={"rate"}
                type={"number"}
                label={"Rate"}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">{currency}</InputAdornment>
                  ),
                }}
              />
            </Stack>

            <DialogActions sx={{ justifyContent: "space-between" }}>
              <Button
                className="btn white-btn action-button "
                onClick={onClose}
                appearance="subtle"
              >
                Cancel
              </Button>
              <Button
                className={`btn action-button primary_color text-white`}
                appearance="primary"
                type={"submit"}
                disabled={!formik.isValid}
                loading={loading}
              >
                {isEdit ? "Update" : "Add"}
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </>
  );
};

export default AddNewOfferModal;
