import React, { useMemo, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LinkIcon from "@mui/icons-material/Link";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { format } from "date-fns";
import { DataGrid } from "@mui/x-data-grid";
import { Routes } from "../../../../../routes";
import {
  formatCurrency,
  formatWithoutTimezone,
  rowsPerPageOptions,
  tableCellStyle,
  tableStyle,
  transactionStatusColors,
} from "../../../../../utils/common";
import { Label } from "../../../../../components";
import WalletTrustLineSet from "../../../../../components/model/WalletTrustLineSet";
import useDeleteModal from "../../../../../hooks/useDeleteModal";
import { DeleteModal } from "../../../../../components/deleteModal";
import { Box, Stack, Typography, LinearProgress, Tooltip } from "@mui/material";

import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const ExchangeOffersTable = ({
  offers,
  paginateTable,
  setPaginateTable,
  fetchOffersLoading,
  setOfferToEdit,
  setIsEdit,
  toggleAddNewOfferModal,
  deleteOffer,
}) => {
  const [deleteId, setDeleteId] = useState("");
  const [showTrustlineModal, setShowTrustlineModal] = useState(false);

  const { showDeleteModal, toggleShowDeleteModal } = useDeleteModal();

  const history = useHistory();
  const permission = useSelector((state) => state.profileReducer?.permission);
  const user = useSelector((state) => state.profileReducer?.user);
  const role = user?.role?.key;

  const userCurrency = user?.mto?.currency ?? {};

  const allCoins = useSelector((state) => state.walletsReducer.coins);

  const confirmDelete = () => {
    deleteOffer(deleteId);
    toggleShowDeleteModal();
  };

  const NoRowsOverlay = () => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        {paginateTable.search === "" ? "No Offers Created" : "No Offers Found"}
      </Stack>
    );
  };

  const createDataRow = (offer) => {
    return {
      id: offer.id,
      currencyPair: (() => {
        return `${offer.coin.symbol}/${offer.currency.code}`;
      })(),
      rate: (() => {
        return (
          formatCurrency(
            offer.rate,
            userCurrency.decimal_place,
            userCurrency.code,
            userCurrency.symbol_prefix
          ) || "--"
        );
      })(),
      // validTill: formatWithoutTimezone(offer?.valid_to, "dd MMM yyyy HH:mm a"),
      // is_active: offer?.is_active,
      // remarks: offer,
      actions: offer,
    };
  };

  const rows = useMemo(
    () =>
      offers.length > 0 ? offers.map((offer) => createDataRow(offer)) : [],
    [offers]
  );

  const columns = useMemo(
    () => [
      {
        field: "currencyPair",
        headerName: "Currency Pair",
        sortable: false,
        flex: 1,
        minWidth: 150,
        // maxWidth: 250,
        headerClassName: "header",
        renderCell: (params) => {
          return (
            <Typography variant="body2" style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          );
        },
        // align: 'center',
        // headerAlign: 'center',
      },

      {
        field: "rate",
        headerName: "Rate",
        sortable: false,
        flex: 1.5,
        minWidth: 100,
        headerClassName: "header",
        renderCell: (params) => {
          return (
            <Typography variant="body2" sx={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          );
        },
        // align: 'center',
      },
      // {
      //   field: "validTill",
      //   headerName: "Valid Till",
      //   flex: 1,
      //   minWidth: 200,
      //   // maxWidth: 200,
      //   headerClassName: "header",
      //   renderCell: (params) => {
      //     return (
      //       <Typography variant="body2" style={tableCellStyle}>
      //         {params.formattedValue}
      //       </Typography>
      //     );
      //   },
      // },
      // {
      //   field: "is_active",
      //   headerName: "Status",
      //   sortable: false,
      //   // flex: 1,
      //   minWidth: 100,
      //   headerClassName: "header",
      //   renderCell: (params) => {
      //     let value = params?.formattedValue;

      //     switch (value) {
      //       case true:
      //         return (
      //           <Label
      //             variant={"ghost"}
      //             color={"complete"}
      //             sx={{ textTransform: "capitalize" }}
      //           >
      //             Active
      //           </Label>
      //         );
      //       case false:
      //         return (
      //           <Label
      //             variant={"ghost"}
      //             color={"rejected"}
      //             sx={{ textTransform: "capitalize" }}
      //           >
      //             Inactive
      //           </Label>
      //         );

      //       default:
      //         return (
      //           <Label
      //             variant={"ghost"}
      //             color={"rejected"}
      //             sx={{ textTransform: "capitalize" }}
      //           >
      //             Inactive
      //           </Label>
      //         );
      //     }
      //   },
      // },

      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        // flex: 1,
        minWidth: 120,
        // maxWidth: 140,
        headerClassName: "header",
        renderCell: (params) => {
          return (
            <Stack direction="row" spacing={2}>
              {permission?.configurations?.exchangeOffers?.permission?.change?.[
                role
              ] && (
                <Stack>
                  <Tooltip title="Edit" arrow>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onEditClicked(params.value);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              )}
              {permission?.configurations?.exchangeOffers?.permission?.delete[
                role
              ] && (
                <Stack>
                  <Tooltip title="Delete" arrow>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteId(params.value.id);
                        toggleShowDeleteModal();
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Stack>
              )}
            </Stack>
          );
        },
      },
    ],
    [permission, role]
  );

  const onEditClicked = (offer) => {
    setIsEdit(true);
    setOfferToEdit(offer);
    toggleAddNewOfferModal();
  };

  const handleChangeRowsPerPage = useCallback(
    (newSize) => {
      setPaginateTable((prev) => ({
        ...prev,
        currentPage: 0,
        pageSize: newSize,
      }));
    },
    [setPaginateTable]
  );

  return (
    <>
      <Box
        sx={{
          height: 615,
          width: 1,
          "& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
              // backgroundColor: 'rgba(235, 235, 235, .7)',
              fontWeight: 500,
              color: "#667085",
              fontFamily: "Inter, sans-serif",
              fontSize: "14px",
            },
          },
          "& .header": {
            backgroundColor: "#F4F4F4",
            "& div": {
              "& div": {
                "& div": {
                  "& div": {
                    fontSize: "14px",
                    fontWeight: 600,
                    fontFamily: "Inter, sans-serif",
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={{ ...tableStyle, cursor: "pointer" }}
          components={{ NoRowsOverlay, LoadingOverlay: LinearProgress }}
          // density={'compact'}
          // onRowClick={(row) => {
          //   console.log(row);
          //   history.push(Routes.ExchangeOffers.path + `/${row?.id}`);
          // }}
          rows={rows}
          columns={columns}
          rowHeight={50}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          pageSize={paginateTable.pageSize}
          onPageSizeChange={handleChangeRowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          rowCount={offers.length}
          loading={fetchOffersLoading}
        />
      </Box>
      {showDeleteModal && (
        <DeleteModal
          onClose={toggleShowDeleteModal}
          isOpen={showDeleteModal}
          onSubmit={confirmDelete}
          text={"the offer"}
        />
      )}
      {showTrustlineModal && (
        <WalletTrustLineSet
          allCoins={allCoins}
          // selectedCoin={selectedCoin}
          open={showTrustlineModal}
          handleClose={() => {
            setShowTrustlineModal(!showTrustlineModal);
          }}
        />
      )}
    </>
  );
};

export default ExchangeOffersTable;
